import { useNavigate } from "react-router-dom";
import BottomNav from "../Components/BottomNavigate/BottomNav";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect } from "react";
import Win from "../Components/Win/Win";
import FloatButton from "../Components/FloatButton/FloatButton";
// import { Fetch_Url } from "../Context/Context";
// import axios from "axios";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 230,
//   p: 4,
//   bgcolor: "white",
//   boxShadow: 24,
// };

export default function Home() {
  const navigate = useNavigate();
  // const [notify, setNotify] = useState(false);
  // const [userdetail, setUserdetail] = useState([]);

  // const getUserinfo = () => {
  //   axios
  //     .post(
  //       `${Fetch_Url}/me.php?action=getuserinfo&user=${window.$loginData}`,
  //       {}
  //     )
  //     .then((resp) => {
  //       // console.log(resp, "resp data");
  //       setUserdetail(resp?.data?.user_Data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const [bal, noticeInfo] = userdetail;
  // console.log(bal, noticeInfo, "bal, noticeInfo");

  // useEffect(() => {
  //   getUserinfo();
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
  }, []);

  return (
    <>
      <FloatButton />
      <BottomNav />
      <div className="homeNav">
        <img src="PLAY350 NEW LOGO.png" alt="" />
        <h3>Play 350</h3>
        <a
          href="https://play350.com/Play350.apk"
          download="Play350"
          target="_blank"
          rel="noreferrer"
        >
          Download App
        </a>
        {/* <i
          className="fa fa-bell"
          onClick={() => {
            navigator.vibrate(100);
            setNotify(true); 
          }}
        ></i> */}
        {/* <Modal
          open={notify}
          onClose={() => {
            setNotify(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="signinModalTxt">
              <h2>Notice</h2>
            </div>
            <div className="signinModal">
              <h5>{noticeInfo?.notice}</h5>
            </div>
            <div className="notifyBtn">
              <button
                onClick={() => {
                  setNotify(false);
                }}
              >
                CLOSE
              </button>
            </div>
          </Box>
        </Modal> */}
      </div>
      <Tabs>
        <TabList className="tabHome">
          <Tab>
            <div className="tabIcon">
              <i className="fa fa-trophy"></i>
              <h4>Win</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fas fa-dice"></i>
              <h4>Dice</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-suit-spade-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.184 11.246A3.5 3.5 0 0 1 1 9c0-1.602 1.14-2.633 2.66-4.008C4.986 3.792 6.602 2.33 8 0c1.398 2.33 3.014 3.792 4.34 4.992C13.86 6.367 15 7.398 15 9a3.5 3.5 0 0 1-6.184 2.246 19.92 19.92 0 0 0 1.582 2.907c.231.35-.02.847-.438.847H6.04c-.419 0-.67-.497-.438-.847a19.919 19.919 0 0 0 1.582-2.907z" />
              </svg>
              <h4>Card</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fa fa-soccer-ball"></i>
              <h4>Red & Black</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fas fa-coins"></i>
              <h4>Head Tale</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fa fa-plane"></i>
              <h4>Aviator</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fas fa-shopping-bag"></i>
              <h4>Lucky 100</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fa fa-spinner"></i>
              <h4>Lucky Spin</h4>
            </div>
          </Tab>
          <Tab>
            <div className="tabIcon">
              <i className="fas fa-award"></i>
              <h4>Lottery</h4>
            </div>
          </Tab>
        </TabList>
        <TabPanel>
          <Win />
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="homeTxt">
            <h5>This Contest Coming Soon</h5>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
}

/* Home CSS */
