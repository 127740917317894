import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 200,
//   bgcolor: "white",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   border: "0px solid white",
// };

export default function BottomNav() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  // const [open2, setOpen2] = useState(false);
  // const [nav, setNav] = useState(false);

  // useEffect(() => {
  //   if (localStorage.getItem("loginData")) {
  //     setNav(true);
  //   } else {
  //     setNav(false);
  //   }
  // }, []);

  // const redirectProfile = () => {
  //   nav ? navigate("/") : navigate("/signin");
  // };

  const handleClick = (tabindex) => {
    setActiveTab(tabindex);
  };

  return (
    <>
      <div className="bottomNav">
        <div
          className={`botInfo ${activeTab === 0 ? "activeTab" : ""}`}
          onClick={() => {
            handleClick(0);
            navigate("/");
          }}
        >
          <div className="botImg">
            <i className="fa fa-home"></i>
          </div>
          <h4>Home</h4>
        </div>
        <div
          className={`botInfo ${activeTab === 1 ? "activeTab" : ""}`}
          onClick={() => {
            handleClick(1);
            navigate("/promotion");
            // setOpen2(true);
          }}
        >
          <div className="botImg">
            <i className="fa fa-gift"></i>
          </div>
          <h4>Invite</h4>
        </div>
        {/* <Modal
          open={open2}
          onClose={() => {
            setOpen2(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="promotionTab">
              <div className="signinModalTxt">
                <h2>Notice</h2>
              </div>
              <div className="signinModal">
                <h5>
                  Promotion Bonus:₹ 0 Total People 0 Contribution ₹ 0 My
                  Promotion Code 58Q18JQ5 My Promotion Link
                  https://colourwins.in//#/register?r_code=58Q18JQ5 Copy Link
                  search ID Phone Water reward First reward 0-10 of 0 Home
                  Search Win My Notice When your friends trade, you will also
                  receive a 30% commission. Therefore, the more friends you
                  invite, the higher your commission. There is a fixed income
                  every day, the commission is permanent, but the reward is only
                  onceWhen they make money, they will invite their friends to
                  join them, and then you can get a 20% commission. In this way,
                  your team can spread quickly. Therefore, I hope everyone can
                  use our platform to make money, make money, and make
                  money!When they make money, they will invite their friends to
                  join them, and then you can get a 20% commission. In this way,
                  your team can spread quickly. Therefore, I hope everyone can
                  use our platform to make money, make money, and make
                  money!Level 1 commission: Friends who join through your own
                  link belong to your level, when they trade, you will get 30%
                  commission.Tier 2 commission: Friends who join through your
                  friend link belong to your secondary commission. When they
                  trade, you can get 20% commission.Level 3 commission: Friends
                  who join through friends of friends belong to your level 3.
                  When they trade, you get 10% commission.Promotional rewards:
                  10% bonus amount for the first recharge after the first-level
                  lower level joins. If your friend joins through your
                  invitation and recharges 1000 for the first time, you will get
                  200.
                </h5>
              </div>
              <div className="promotionTabBtn">
                <button
                  onClick={() => {
                    setOpen2(false);
                    navigate("/promotion");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Box>
        </Modal> */}

        {/* {nav && ( */}
        <div
          className={`botInfo ${activeTab === 2 ? "activeTab" : ""}`}
          onClick={() => {
            handleClick(2);
            navigate("/profile");
          }}
        >
          <div className="botImg">
            <i className="fa fa-user"></i>
          </div>
          <h4>Profile</h4>
        </div>
        {/* )} */}
        {/* <div
          className="botInfo"
          // className= { color4 ? "changeColor" : "botInfo"
          // }
          onClick={redirectProfile}
        >
          <div className="botImg">
            <i className="fa fa-user"></i>
          </div>
          <h4>Profile</h4>
        </div> */}
      </div>
    </>
  );
}

/* BottomNav CSS */
